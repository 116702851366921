<template>
<div class="rootcontainer" 
  :class="activeClass"
>
  <div class="container"
  
  >
        <p
          class="back-to-top"
          :class="localDisplayLang === 'ara' ? 'back-to-top-rtl' : ''"
          :style="[
            showHoveredColor
              ? { color: '$clr-top-down-ico', 'background-color':'$clr-top-down-hover-bg' }
              : { color: '$clr-top-down-hover-bg', 'background-color': '$clr-top-down-ico' },
          ]"
          @click="scrollTop"
          @mouseover="hoverEffect"
          @mouseleave="noHoverEffect"
        >
          
          <span>
            <img class="arrow-img" src="@/assets/icons/arrow-up.png" alt="" />
          </span>
        </p>
       
      </div>
</div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  data() {
     return {
       showHoveredColor: false,
      localDisplayLang: null,
      activeClass: 'hide',
     }
  },
  components : {
 BackToTopCopy: () =>
      import(
        /* webpackChunkName: "backtotop" */ "@/components/SvgImages/BackToTopCopy.vue"
      ),
  },

created() {
  this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    window.addEventListener('scroll', this.onScroll);
},

destroyed(){
  window.removeEventListener('scroll', this.onScroll);
},

methods : {

  scrollTop() {
      window.scrollTo(0, 0);
    },

    hoverEffect() {
      this.showHoveredColor = true;
    },

    noHoverEffect() {
      this.showHoveredColor = false;
    },

onScroll() {
  if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
   this.activeClass = 'show';
  
   
  } else {
  this.activeClass = 'hide';
 
  }

}

}

}

</script>




<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./BackToTop.scss"

</style>
